import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H2, BodyText, Divider } from "@/components/typography";
import type { RestaurantsStoryblok } from "@/component-types-sb";

type RestaurantsProps = {
  blok: RestaurantsStoryblok;
};

const Restaurants = ({ blok }: RestaurantsProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <header>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>

    <section className="py-8 mx-2 lg:mx-4 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>

      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.description}
      </BodyText>

      <Divider />

      <div className="py-8 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 text-left">
        {blok.body?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>
  </article>
);

export default Restaurants;
