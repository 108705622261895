"use client";

import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { track } from "@vercel/analytics";
import { useChat } from "ai/react";
import { clsx } from "clsx";
import { ChatBubbleLeftRightIcon, XMarkIcon } from "@heroicons/react/24/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { useKeyPress } from "@/lib/hooks/useKeyPress";

import { Clipboard } from "@/components/icons/Clipboard";

const Chat = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const messageRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const { messages, input, handleInputChange, handleSubmit } = useChat({
    initialMessages: [
      {
        id: "hello",
        content: "Hallo, wie kann ich Dir helfen?",
        role: "assistant",
      },
    ],
    onResponse: (response) => {
      if (response.status === 429) {
        console.error("You have reached your request limit for the day.");
        track("Rate limited");
        return;
      } else {
        track("Chat initiated");
      }
    },
    onError: (error: Error) => {
      console.error(error);
    },
  });

  const lastMessage = messages[messages.length - 1];
  const generatedMessage =
    lastMessage?.role === "assistant" ? lastMessage.content : null;

  const scrollToBottom = () => {
    if (messageRef.current !== null) {
      messageRef.current.scrollIntoView({ behavior: "instant" });
    }
  };

  useEffect(() => {
    if (messageRef.current) {
      scrollToBottom();
    }
  }, [generatedMessage]);

  useEffect(() => {
    if (isExpanded) {
      inputRef.current?.focus();
    }
  }, [isExpanded]);

  useKeyPress({
    keyPressItems: [
      {
        keys: ["Escape"],
        event: () => setIsExpanded(false),
      },
      {
        keys: ["Meta", "KeyK"],
        event: () => {
          setIsExpanded(true);
        },
      },
    ],
  });

  return (
    <div
      className={clsx(
        "fixed bottom-0 right-0 z-10 max-w-xl drop-shadow-md transition-all ease-out duration-300",
        {
          ["w-full h-auto bg-white"]: isExpanded,
          ["w-12 h-12"]: !isExpanded,
        }
      )}
    >
      {/* Close Icon */}
      <div className="flex flex-col items-end cursor-pointer">
        <XMarkIcon
          className={clsx("w-6 h-6 text-black m-2", {
            ["block"]: isExpanded,
            ["hidden"]: !isExpanded,
          })}
          onClick={() => setIsExpanded(false)}
        />

        {/* Open Icon */}
        <div
          className={clsx(
            "items-center justify-center w-10 h-10 rounded-full bg-white mr-2",
            {
              ["hidden"]: isExpanded,
              ["inline-flex"]: !isExpanded,
            }
          )}
          onClick={() => setIsExpanded(true)}
        >
          <ChatBubbleLeftRightIcon className="w-6 h-6 text-brand-secondary100" />
        </div>
      </div>

      <section
        className={clsx("flex-1 h-full max-h-96 overflow-y-scroll", {
          ["flex-row"]: isExpanded,
          ["hidden"]: !isExpanded,
        })}
      >
        {messages.map((m) => (
          <div key={m.id} className="mb-2 flex items-start gap-2.5 p-3">
            {m.role === "assistant" ? (
              <Image
                src="/favicon.svg"
                width="32"
                height="32"
                alt="KNAUS"
                className="w-8 h-8"
              />
            ) : (
              <PencilIcon className="w-4 h-4 mx-2 text-brand-secondary100" />
            )}

            <div className="flex flex-col gap-1 w-full">
              <div className="flex items-center space-x-2 rtl:space-x-reverse">
                <span className="text-xs font-semibold text-gray-900">
                  {m.role === "assistant" && "KNAUS "}
                </span>
              </div>
              <div
                className={clsx(
                  "flex flex-col leading-1.5 p-4 rounded-e-xl rounded-es-xl",
                  {
                    ["bg-gray-100"]: m.role === "assistant",
                    ["bg-white border border-gray-200"]: m.role === "user",
                  }
                )}
              >
                <p className="text-sm font-normal text-gray-900">{m.content}</p>
              </div>
            </div>
            {m.role === "assistant" && (
              <Clipboard text={m.content} className="place-self-center" />
            )}
          </div>
        ))}
        <div className="pt-12" ref={messageRef}></div>
      </section>

      <form
        onSubmit={handleSubmit}
        className={clsx(
          "w-full p-4 text-center bg-white border-t-[1px] border-gray-200",
          {
            ["flex-col"]: isExpanded,
            ["hidden"]: !isExpanded,
          }
        )}
      >
        <label
          htmlFor="message"
          className="mb-2 text-sm font-medium text-gray-900 sr-only"
        >
          Frage
        </label>
        <div className="relative">
          <input
            type="search"
            id="message"
            name="message"
            ref={inputRef}
            value={input}
            onChange={handleInputChange}
            className="block w-full p-2 text-sm text-gray-900 bg-gray-50 appearance-none focus:outline-none focus:ring-0 focus:border-brand-secondary100"
            placeholder="Gib hier Deine Frage ein..."
            tabIndex={0}
            autoFocus
            spellCheck={false}
            required
          />
          <div className="w-auto absolute end-2.5 bottom-1.5">
            <button
              type="submit"
              disabled={!input}
              className={clsx("bg-brand-primary100 text-white p-1.5", {
                ["bg-opacity-50"]: !input,
              })}
            >
              <svg
                className="w-3 h-3"
                fill="currentColor"
                viewBox="0 0 49 54"
                aria-hidden="true"
              >
                <path d="M48 27V1h-6v26a5 5 0 0 1-5 5H10.24l11-11L17 16.76.88 32.88a3 3 0 0 0 0 4.24L17 53.24 21.24 49l-11-11H37c6.075 0 11-4.925 11-11Z" />
              </svg>
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export { Chat };
