import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { NewsletterForm } from "@/components/form/NewsletterForm";
import { H2, BodyText, Divider } from "@/components/typography";
import type { NewsletterStoryblok } from "@/component-types-sb";

type NewsletterProps = {
  blok: NewsletterStoryblok;
};

const Newsletter = ({ blok }: NewsletterProps) => {
  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="relative max-w-6xl mx-auto py-8 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        {/* {blok.form?.map((nestedBlok: any) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))} */}

        {blok.form?.[0] && <NewsletterForm label={blok.form[0].label} />}
      </section>
    </article>
  );
};

export default Newsletter;
