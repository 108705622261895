"use client";

import { FormEvent } from "react";
import { useFormStatus } from "react-dom";
import type { ComponentProps } from "react";
import { clsx } from "clsx";

type SubmitButtonProps = {
  label?: string;
} & ComponentProps<"button">;

const SubmitButton = ({ label, ...rest }: SubmitButtonProps) => {
  const { pending } = useFormStatus();

  return (
    <button
      type="submit"
      onClick={(e: FormEvent<HTMLButtonElement>) => {
        if (pending) {
          e.preventDefault();
        }
      }}
      aria-disabled={pending}
      className={clsx(
        "uppercase inline-flex items-center px-3 py-2 text-sm font-medium text-center bg-brand-primary100 text-white",
        {
          "cursor-not-allowed opacity-60 hover:opacity-60": pending,
        },
        rest.className
      )}
    >
      {pending && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}

      {label}
    </button>
  );
};

export { SubmitButton };
