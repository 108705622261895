import { useFormState } from "react-dom";

import { SubmitButton } from "@/components/form/SubmitButton";
import { subscribeNewsletter } from "@/lib/actions/subscribeNewsletter";
import type { FormState } from "@/lib/actions/subscribeNewsletter";

type NewsletterFormProps = {
  label?: string;
};

const initialState: FormState = {};

const NewsletterForm = ({ label }: NewsletterFormProps) => {
  const [state, formAction] = useFormState(subscribeNewsletter, initialState);

  return (
    <form
      className="bg-gray-300 flex items-end p-4 m-4 gap-4 max-w-xl mx-auto"
      action={formAction}
    >
      <div className="relative w-full">
        <label
          htmlFor="email"
          className="hidden mb-2 text-sm font-medium text-gray-900"
        >
          E-Mail
        </label>
        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
          <svg
            className="w-5 h-5 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
            <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
          </svg>
        </div>
        <input
          name="email"
          type="email"
          required
          autoComplete="email"
          placeholder="E-Mail"
          className="w-full border-none py-2 pl-10 pr-10 text-sm leading-5 text-gray-900 focus:ring-0 outline-none"
        />
      </div>

      <SubmitButton label={label} />

      <p aria-live="polite" className="sr-only">
        {state?.error?.message}
      </p>
    </form>
  );
};

export { NewsletterForm };
