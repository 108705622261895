import { useState } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Filter } from "@/components/layout/Filter";
import { H2, BodyText, Divider } from "@/components/typography";
import type { FaqStoryblok, CampsiteStoryblok } from "@/component-types-sb";

type FaqProps = {
  blok: FaqStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
};

const Faq = ({ blok }: FaqProps) => {
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto pb-8 text-left">
          <Filter onSelect={setSelectedCampsite} />

          <div className="grid grid-cols-1 gap-8">
            {blok.questions?.map((nestedBlok) => (
              <StoryblokComponent
                blok={nestedBlok}
                key={nestedBlok._uid}
                campsite={selectedCampsite}
              />
            ))}
          </div>
        </div>
      </section>
    </article>
  );
};

export default Faq;
