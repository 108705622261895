import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";

import { H2, BodyText, RichText, Divider } from "@/components/typography";
import { Card } from "@/components/layout/Card";
import type { CycleRouteStoryblok } from "@/component-types-sb";

type CycleRouteProps = {
  blok: CycleRouteStoryblok;
};

const CycleRoute = ({ blok }: CycleRouteProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <section className="py-8 mx-2 lg:mx-4 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.name}
      </H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.subline}
      </BodyText>

      <Divider />

      <div className="max-w-5xl mx-auto pb-8 text-left">
        <div className="text-gray-500">
          <RichText html={renderRichText(blok.content)} />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mt-16">
          {blok.campsites?.map((nestedBlok: any) => (
            <Card
              key={nestedBlok.id}
              image={nestedBlok.content?.image}
              href={nestedBlok.full_slug}
              name={nestedBlok.name}
              text={nestedBlok.content?.lead}
              buttonLabel="Entdecken"
            />
          ))}
        </div>
      </div>
    </section>
  </article>
);

export default CycleRoute;
