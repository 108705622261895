import Link from "next/link";
import Image from "next/image";
import { storyblokEditable } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { clsx } from "clsx";

import { Button } from "@/components/layout/Button";
import { H4, BodyText } from "@/components/typography";
import { RichText } from "@/components/typography";
import type { RestaurantTeaserStoryblok } from "@/component-types-sb";
import imageLoader from "@/lib/utils/imageLoader";

type RestaurantTeaserProps = {
  blok: RestaurantTeaserStoryblok;
};

const RestaurantTeaser = ({ blok }: RestaurantTeaserProps) => (
  <section className="flex flex-col gap-4" {...storyblokEditable(blok)}>
    <Image
      className={clsx("object-cover w-full h-64 aspect-square", {
        "order-last": blok.reverse,
      })}
      loader={imageLoader}
      src={blok.image?.filename || ""}
      width={300}
      height={300}
      sizes="20vw"
      alt={blok.image?.alt || ""}
    />

    <H4
      as="h3"
      colorScheme="primary"
      fontWeight="bold"
      fontSize="lg"
      className="mb-4"
    >
      {blok.headline}
    </H4>

    <p className="max-w-md mx-auto mt-2 text-black">{blok.text}</p>

    <div className="bg-gray-50 p-4">
      <RichText html={renderRichText(blok.opening_hours)} />
    </div>

    <a
      href={blok.menu?.filename}
      target="_blank"
      download
      className="inline-flex gap-1 items-center"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 142 142"
        className="w-6 h-6"
      >
        <path
          fill="#e30613"
          fillRule="evenodd"
          d="M56.179 117.1H31.88V76.758h6.235v34.107H56.18v6.235Zm10.636-59.468v-2.498H57.98v11.218h2.793v-4.213h5.486v-2.498h-5.486v-2.008h6.042ZM48.41 63.82v-6.156h1.732c1.91 0 3.282 1.273 3.282 3.07 0 1.796-1.371 3.086-3.282 3.086H48.41Zm-2.792 2.53h4.098c3.854 0 6.564-2.334 6.564-5.616 0-3.283-2.712-5.601-6.564-5.601h-4.098v11.218Zm-8.116-6.057v-2.612h2.156c.964 0 1.65.523 1.65 1.306s-.686 1.306-1.65 1.306h-2.156Zm-2.792 6.058h2.792V62.84h2.417c2.466 0 4.262-1.585 4.262-3.854 0-2.27-1.796-3.854-4.262-3.854h-5.21V66.35Zm-7.375-19.027h33.435c7.38 0 13.418 6.039 13.418 13.418 0 7.38-6.037 13.418-13.418 13.418H27.336V47.325Zm56.64 52.38v2.612h8.238l-17.807 20.44-17.422-20.44h7.523v-2.613h19.467Zm0-3.466v2.805H64.507v-2.805h19.467Zm0-3.409v2.749H64.507V92.83h19.467Zm0-3.575v2.915H64.507v-2.915h19.467Zm0-2.804v2.144H64.507v-2.144h19.467Zm0-2.034v1.375H64.507v-1.375h19.467ZM64.507 82.74h19.467v1.018H64.508V82.74Zm50.153-33.144V117.1H92.307v-6.234h16.12V51.589H82.754l-.125-24.452H38.115V44.73H31.88V20.902h53.792l28.99 28.695Z"
        />
      </svg>
      {blok.menu?.title || "Speisekarte"}
    </a>

    {blok.link?.cached_url && (
      <Link
        href={`${blok.link.linktype === "story" ? "/" : ""}${
          blok.link.cached_url
        }`}
        target={blok.link.target}
      >
        <Button colorScheme="primary" className="my-2">
          {blok.label}
        </Button>
      </Link>
    )}
  </section>
);

export default RestaurantTeaser;
