import type { ConfigStoryblok } from "@/component-types-sb";

type ConfigPops = {
  blok: ConfigStoryblok;
};

const Config = ({ blok }: ConfigPops) => (
  <div className="w-full h-screen"></div>
);

export default Config;
