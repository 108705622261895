import Link from "next/link";
import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import type { EmailStoryblok } from "@/component-types-sb";
import { Tab } from "@headlessui/react";
import { clsx } from "clsx";

import { siteUrl } from "@/config/site";

type EmailPops = {
  blok: EmailStoryblok;
  html: string;
};

const Email = ({ blok, html }: EmailPops) => (
  <article
    className="mx-auto w-[630px] text-center"
    {...storyblokEditable(blok)}
  >
    <Tab.Group>
      <Tab.List className="flex space-x-1 bg-gray-100 p-1">
        <Tab
          className={({ selected }) =>
            clsx(
              "w-full py-2.5 text-sm font-medium leading-5 text-brand-primary100 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary100 focus:outline-none focus:ring-2",
              {
                ["bg-white shadow-sm"]: selected,
                ["text-brand-primary100 hover:bg-white/[0.12]"]: !selected,
              }
            )
          }
        >
          Vorschau
        </Tab>
        <Tab
          className={({ selected }) =>
            clsx(
              "w-full py-2.5 text-sm font-medium leading-5 text-brand-primary100 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary100 focus:outline-none focus:ring-2",
              {
                ["bg-white shadow-sm"]: selected,
                ["text-brand-primary100 hover:bg-white/[0.12]"]: !selected,
              }
            )
          }
        >
          HTML
        </Tab>
      </Tab.List>
      <Tab.Panels className="mt-2">
        <Tab.Panel className="bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary200 focus:outline-none focus:ring-2">
          <div>
            {/* Header */}
            <section className="bg-white">
              <Link href="/">
                <Image
                  src="/logo.svg"
                  alt="KNAUS CAMPINGPARKS"
                  width={207}
                  height={90}
                  className="mx-auto"
                />
              </Link>
            </section>

            <header className="bg-white p-4 text-center">
              {blok.header?.map((nestedBlok) => (
                <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
              ))}
            </header>

            {/* Main */}
            <section className="bg-gray-50 p-4 text-center">
              <h2 className="text-xl font-semibold">{blok.title}</h2>

              <div className="font-normal text-gray-500 text-[14px] leading-[24px]">
                <span
                  dangerouslySetInnerHTML={{
                    __html: renderRichText(blok.text),
                  }}
                />
              </div>
            </section>

            <section className="bg-white p-4 my-8">
              <h3 className="text-3xl text-brand-secondary100 font-light mb-4">
                Veranstaltungen
              </h3>

              <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

              <div className="flex flex-col gap-12">
                {blok.events?.map((event: any) => (
                  <div className="flex items-start gap-6" key={event.id}>
                    <Link
                      href={`${siteUrl}/${event.full_slug}`}
                      className="w-1/3"
                    >
                      <Image
                        src={
                          event.content?.images?.[0]?.filename ||
                          event.content?.campsite?.content?.image?.filename
                        }
                        alt={""}
                        width={260}
                        height={260}
                        className="aspect-square w-full h-auto object-contain"
                      />
                    </Link>
                    <div className="flex flex-col items-start gap-2 w-2/3 text-left">
                      <h3 className="text-2xl text-gray-900">{event.name}</h3>
                      <p className="text-sm text-gray-500">
                        {event.content?.description}
                      </p>
                      <Link
                        href={`${siteUrl}/${event.full_slug}`}
                        className="bg-brand-secondary100 text-white uppercase inline-flex items-center px-3 py-2 text-sm font-medium text-center"
                      >
                        Mehr Infos
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="bg-white p-4 my-8">
              <h3 className="text-3xl text-brand-secondary100 font-light mb-4">
                News
              </h3>

              <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

              <div className="flex flex-col gap-12">
                {blok.news?.map((news: any) => (
                  <div key={news.id} className="flex items-start gap-6">
                    <Link
                      href={`${siteUrl}/${news.full_slug}`}
                      className="w-1/3"
                    >
                      <Image
                        src={
                          news.content?.images?.[0]?.filename ||
                          news.content?.campsite?.content?.image?.filename
                        }
                        alt={""}
                        width={260}
                        height={260}
                        className="aspect-square w-full h-auto object-contain"
                      />
                    </Link>
                    <div className="flex flex-col items-start gap-2 w-2/3 text-left">
                      <h3 className="text-2xl text-gray-900">{news.name}</h3>
                      <p className="text-sm text-gray-500">
                        {news.content?.abstract}
                      </p>
                      <Link
                        href={`${siteUrl}/${news.full_slug}`}
                        className="bg-brand-secondary100 text-white uppercase inline-flex items-center px-3 py-2 text-sm font-medium text-center"
                      >
                        Mehr Infos
                      </Link>
                    </div>
                  </div>
                ))}
              </div>
            </section>

            <section className="bg-white p-4 my-8">
              <h3 className="text-3xl text-brand-secondary100 font-light mb-4">
                Angebote
              </h3>

              <hr className="border border-solid border-[#eaeaea] my-[26px] mx-0 w-full" />

              {blok.offers?.map((offer: any) => (
                <div key={offer.id} className="flex items-start gap-6">
                  <Link
                    href={`${siteUrl}/${offer.full_slug}`}
                    className="w-1/3"
                  >
                    <Image
                      src={offer.content?.image?.filename}
                      alt={offer.content?.image?.alt || ""}
                      width={260}
                      height={260}
                      className="aspect-square w-full h-auto object-contain"
                    />
                  </Link>
                  <div className="flex flex-col items-start gap-2 w-2/3 text-left">
                    <h3 className="text-2xl text-gray-900">{offer.name}</h3>
                    <p className="text-sm text-gray-500">
                      {offer.content?.abstract}
                    </p>
                    <Link
                      href={`${siteUrl}/${offer.full_slug}`}
                      className="bg-brand-secondary100 text-white uppercase inline-flex items-center px-3 py-2 text-sm font-medium text-center"
                    >
                      Mehr Infos
                    </Link>
                  </div>
                </div>
              ))}
            </section>

            {/* Footer */}
            <section className="bg-brand-secondary100 text-white p-4">
              <p className="text-[12px] leading-[24px]">
                Helmut KNAUS KG
                <br />
                Sitz: Ochsenfurt, Marktbreiter Str. 11
                <br />
                Verantwortlicher: Helmut Knaus, Sandra Knaus
                <br />
                HReg: Registergericht Würzburg HRA 1627
              </p>

              <hr className="border-gray-400 my-4" />

              <p className="text-[12px]">
                <Link href="/datenschutz" className="text-white pr-4">
                  Datenschutz
                </Link>
                <Link
                  href="/api/newsletter/unsubscribe?uuid="
                  className="text-white"
                >
                  Vom Newsletter abmelden
                </Link>
              </p>
            </section>
          </div>
        </Tab.Panel>
        <Tab.Panel className="bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary200 focus:outline-none focus:ring-2">
          <pre className="bg-gray-800 text-white p-4 text-xs font-mono font-thin leading-relaxed overflow-scroll text-left">
            {html}
          </pre>
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  </article>
);

export default Email;
