import Link from "next/link";
// import { useSearchParams } from "next/navigation";
import { useEffect, useState, Fragment } from "react";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { Skeleton } from "@/components/layout/Skeleton";
import { Filter } from "@/components/layout/Filter";
import { IndeedTeaser } from "@/components/layout/IndeedTeaser";
import { H2, BodyText, Tag, Divider } from "@/components/typography";
import type {
  JobOverviewStoryblok,
  JobStoryblok,
  CampsiteStoryblok,
} from "@/component-types-sb";
import { getJobs } from "@/lib/storyblok/getJobs";

type JobOverviewProps = {
  blok: JobOverviewStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
};

const JobOverview = ({ blok, searchParams }: JobOverviewProps) => {
  // const searchParams = useSearchParams();

  const [jobs, setJobs] = useState<JobStoryblok[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedCampsite, setSelectedCampsite] =
    useState<CampsiteStoryblok | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getJobs({
        filter_query: {
          campsite: { in: searchParams.campsite || null },
        },
      });
      setJobs(data);
      setIsLoading(false);
    };
    fetchData();
  }, [searchParams.campsite]);

  return (
    <article className="w-full" {...storyblokEditable(blok)}>
      <header>
        {blok.header?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </header>

      <section className="py-8 text-center">
        <Breadcrumb
          items={[
            { href: "/karriere", title: "Karriere" },
            { href: "/jobs", title: "Stellenanzeigen" },
          ]}
          className="flex flex-col items-center"
        />
        <H2 as="h1" colorScheme="primary">
          {blok.title}
        </H2>
        <BodyText className="max-w-md mx-auto mt-2 text-center">
          {blok.description}
        </BodyText>

        <Divider />

        <div className="max-w-5xl mx-auto mb-24 grid gap-8 text-left">
          <Filter onSelect={setSelectedCampsite} type="jobs" />

          {isLoading && <Skeleton number={3} />}

          <div className="grid grid-cols-1 gap-12 py-8">
            {jobs
              ?.filter((item: any) =>
                selectedCampsite
                  ? item.content?.campsites
                      .map((campsite: any) => campsite.uuid)
                      .includes(selectedCampsite?.uuid)
                  : true
              )
              .map((nestedBlok) => (
                <Link
                  key={nestedBlok.id}
                  className="flex flex-col gap-2 bg-gray-100 p-4"
                  href={nestedBlok.full_slug}
                >
                  <div className="flex gap-2">
                    {nestedBlok.content?.employmentTypes?.map(
                      (employmentType: string) => (
                        <Tag key={employmentType} colorScheme="outline">
                          {employmentType}
                        </Tag>
                      )
                    )}
                    {nestedBlok.content?.employmentDuration && (
                      <Tag colorScheme="secondary">
                        {nestedBlok.content.employmentDuration}
                      </Tag>
                    )}
                  </div>

                  <div className="flex gap-1 text-xl text-gray-900">
                    <h3 className="font-semibold">
                      {nestedBlok.content?.title}
                    </h3>
                    <span className="font-light">
                      {nestedBlok.content?.subline}
                    </span>
                  </div>

                  {nestedBlok.content?.campsites?.length > 0 && (
                    <ul className="flex flex-wrap gap-1 items-center text-gray-600 my-2">
                      <li>Standorte:</li>
                      {nestedBlok.content?.campsites.map((item: any) => (
                        <li key={item.id}>{item.name},</li>
                      ))}
                    </ul>
                  )}
                </Link>
              ))}
          </div>
        </div>
      </section>

      <IndeedTeaser />
    </article>
  );
};

export default JobOverview;
