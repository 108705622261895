import Link from "next/link";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { Button } from "@/components/layout/Button";
import { JobSearchForm } from "@/components/layout/JobSearchForm";
import { IndeedTeaser } from "@/components/layout/IndeedTeaser";
import { H1, BodyText } from "@/components/typography";
import type { CareersStoryblok } from "@/component-types-sb";

type CareersProps = {
  blok: CareersStoryblok;
};

const Careers = ({ blok }: CareersProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <header>
      {blok.header?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </header>

    <section className="bg-white relative max-w-4xl mx-auto flex flex-col gap-4">
      <div className="bg-brand-primary100 grid grid-cols-2 p-8">
        <H1 colorScheme="white" fontSize="5xl">
          {blok.headline}
        </H1>
        <div>
          <BodyText className="text-white">{blok.description}</BodyText>
          <Link href="/jobs">
            <Button colorScheme="outline">Zur Jobbörse</Button>
          </Link>
        </div>
      </div>

      <JobSearchForm />

      <div className="grid grid-cols-2 gap-8 md:gap-32 pb-8 m-4">
        {blok.imageTeasers?.map((nestedBlok) => (
          <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
        ))}
      </div>
    </section>

    {blok.body?.map((nestedBlok) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}

    <IndeedTeaser />
  </article>
);

export default Careers;
