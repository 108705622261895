import Image from "next/image";
import Link from "next/link";
import { clsx } from "clsx";

import { Button } from "@/components/layout/Button";
import { BodyText, H4 } from "@/components/typography";
import type { AssetStoryblok } from "@/component-types-sb";

type CardSize = "normal" | "large" | "tall";
type Sizes = { [key in CardSize]: { width: number; height: number } };

const sizes: Sizes = {
  normal: {
    width: 420,
    height: 420,
  },
  large: {
    width: 640,
    height: 640,
  },
  tall: {
    width: 420,
    height: 600,
  },
};

type CardProps = {
  image?: AssetStoryblok;
  href?: string;
  name?: string;
  text?: string;
  buttonLabel?: string;
  size?: CardSize;
  hasOffer?: boolean;
  isNew?: boolean;
  className?: string;
};

const Card = ({
  image,
  href = "#",
  name,
  text,
  buttonLabel = "Entdecken",
  size = "normal",
  hasOffer = false,
  isNew = false,
  ...rest
}: CardProps) => {
  const { width, height } = sizes[size];

  return (
    <Link
      href={`/${href}`}
      className={clsx(
        "relative block group bg-gradient-to-t from-black/80 to-100% lg:to-50% to-transparent overflow-hidden text-left",
        rest.className,
        {
          ["lg:col-span-2 lg:col-start-1 lg:row-span-2 lg:row-start-1"]:
            size === "large",
        }
      )}
    >
      {image && (
        <Image
          className={clsx(
            "w-full h-auto object-cover transition duration-500 mix-blend-multiply group-hover:mix-blend-normal group-hover:scale-110",
            rest.className,
            {
              ["aspect-square"]: size !== "tall",
              ["aspect-[7/10]"]: size === "tall",
            }
          )}
          src={image.filename || ""}
          width={width}
          height={height}
          quality={65}
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 25vw"
          alt={image.alt || ""}
          title={image.title || ""}
          style={{ height: "auto" }}
        />
      )}

      <div className="absolute inset-0 flex flex-col items-start justify-end p-6">
        <H4
          as="h3"
          colorScheme="white"
          fontWeight="bold"
          className="truncate lg:overflow-clip w-full"
        >
          {name}
        </H4>
        <BodyText className="text-white leading-5 line-clamp-2 lg:line-clamp-none">
          {text}
        </BodyText>
        <Button arrow colorScheme="outline">
          {buttonLabel}
        </Button>
      </div>

      {isNew ? (
        <Image
          src="/neu.svg"
          alt="Neu"
          width={80}
          height={40}
          className="w-1/5 absolute top-2 right-2 group-hover:animate-wiggle"
          title="Neu"
        />
      ) : hasOffer ? (
        <Image
          src="/offer.svg"
          alt="%"
          width={200}
          height={200}
          className="w-1/5 absolute top-2 right-2 group-hover:animate-wiggle aspect-square"
          title="Angebot vorhanden"
        />
      ) : null}
    </Link>
  );
};

export { Card };
export type { CardSize };
