"use client";

import { useRouter } from "next/navigation";
import { FormEvent, useState, useCallback, useEffect } from "react";

import { Button } from "@/components/layout/Button";
import { Autocomplete, Select } from "@/components/form";
import type {
  JobStoryblok,
  CampsiteStoryblok,
  OfficeStoryblok,
} from "@/component-types-sb";

type Job = Pick<JobStoryblok, "id" | "name" | "uuid">;
type Campsite = Pick<CampsiteStoryblok, "id" | "name" | "uuid">;
type Office = Pick<OfficeStoryblok, "id" | "name" | "uuid">;

const JobSearchForm = () => {
  const router = useRouter();

  const [jobs, setJobs] = useState<Job[] | null>(null);
  const [campsites, setCampsites] = useState<Campsite[] | null>(null);
  const [offices, setOffices] = useState<Office[] | null>(null);

  const getJobs = useCallback(async () => {
    const res = await fetch("/api/jobs");
    const json = await res.json();
    setJobs(json.data);
  }, []);

  const getCampsites = useCallback(async () => {
    const res = await fetch("/api/campsites");
    const json = await res.json();
    setCampsites(json.data);
  }, []);

  const getOffices = useCallback(async () => {
    const res = await fetch("/api/offices");
    const json = await res.json();
    setOffices(json.data);
  }, []);

  const fetchAll = useCallback(async () => {
    await Promise.allSettled([getJobs(), getCampsites(), getOffices()]);
  }, [getJobs, getCampsites, getOffices]);

  useEffect(() => {
    fetchAll();
  }, [fetchAll]);

  const submitForm = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);

    const queryParams: Record<string, any> = {};
    queryParams.job = formData.get("job[uuid]") || "";
    queryParams.campsite = formData.get("campsite[uuid]") || "";

    const queryString = new URLSearchParams(queryParams).toString();
    router.push("/jobs?" + queryString);
  };

  return (
    <form
      className="bg-gray-300 flex items-end p-4 m-4 gap-4"
      onSubmit={submitForm}
    >
      {jobs && (
        <Autocomplete
          data={jobs?.map(({ id, name, uuid }) => ({ id, name, uuid }))}
          name="job"
          placeholder="Welchen Job suchst Du?"
          icon="search"
        />
      )}
      {campsites && offices && (
        <Autocomplete
          data={[...campsites, ...offices].map(({ id, name, uuid }) => ({
            id,
            name,
            uuid,
          }))}
          name="campsite"
          placeholder="Wo suchst Du?"
          icon="location"
        />
      )}

      <Select
        data={[{ name: "25km" }, { name: "50km" }, { name: "100km" }]}
        className="basis-24"
      />
      <Button type="submit" colorScheme="primary">
        Suchen
      </Button>
    </form>
  );
};

export { JobSearchForm };
