"use client";

import { useState } from "react";
import { useCopyToClipboard } from "@/lib/hooks/useCopyToClipboard";
import {
  ClipboardIcon,
  ClipboardDocumentCheckIcon,
} from "@heroicons/react/24/outline";
import { clsx } from "clsx";

type ClipboardProps = {
  text: string;
  className?: string;
};

const Clipboard = ({ text, className }: ClipboardProps) => {
  const [copiedText, copy] = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState<Boolean>(false);

  const handleCopy = (text: string) => () => {
    copy(text);

    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000);
  };

  return (
    <>
      {copiedText && isCopied ? (
        <ClipboardDocumentCheckIcon
          onClick={handleCopy(text)}
          className={clsx("w-6 h-6 text-brand-secondary100", className)}
        />
      ) : (
        <ClipboardIcon
          onClick={handleCopy(text)}
          className={clsx("w-6 h-6 text-brand-secondary100", className)}
        />
      )}
    </>
  );
};

export { Clipboard };
