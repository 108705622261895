import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H4 } from "@/components/typography";
import type { OpeningHoursStoryblok } from "@/component-types-sb";

type OpeningHoursProps = {
  blok: OpeningHoursStoryblok;
};

const OpeningHours = ({ blok }: OpeningHoursProps) => (
  <section
    className="max-w-screen-xl mx-auto py-8 text-center bg-brand-primary100/5"
    {...storyblokEditable(blok)}
  >
    <H4
      as="h3"
      colorScheme="primary"
      fontWeight="bold"
      fontSize="lg"
      className="text-center mb-6"
    >
      {blok.headline}
    </H4>

    <div className="px-6">
      {blok.items?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
    </div>
  </section>
);

export default OpeningHours;
