export const siteUrl = process.env.NEXT_PUBLIC_SITE_URL!;

export const title = "KNAUS Campingparks";
export const description = "Wunsch-Urlaub für Individualisten";
export const phoneNumber = "+499331983180";
export const email = "info@knauscamp.de";
export const siteImage =
  "https://a.storyblok.com/f/249598/3355x2237/40e4590d61/adobestock_595674244_ret.jpg/m/640x0/filters:quality(75)";

export const easybuchenIframeUrl =
  "https://buchen.knauscamp.de/Onlinebuchen/StartWithParam";

export const locale = "de_DE";
