import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";

import { H2, BodyText, Divider } from "@/components/typography";
import type { PricesStoryblok } from "@/component-types-sb";

type PricesProps = {
  blok: PricesStoryblok;
  searchParams: { [key: string]: string | string[] | undefined };
};

const Prices = ({ blok }: PricesProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <section className="py-8 mx-2 lg:mx-4 text-center">
      <H2 as="h1" colorScheme="primary">
        {blok.title}
      </H2>
      <BodyText className="max-w-md mx-auto mt-2 text-center">
        {blok.subline}
      </BodyText>

      <Divider />

      <div className="max-w-5xl mx-auto pb-8 text-left">
        <p className="text-gray-500">{blok.text}</p>

        <div className="grid md:grid-cols-3 gap-12 mt-8 border-b-2 border-gray-200">
          {blok.items?.map((nestedBlok) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>
    </section>
  </article>
);

export default Prices;
