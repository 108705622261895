import Link from "next/link";
import Image from "next/image";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { Tab } from "@headlessui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/solid";
import { clsx } from "clsx";

import { Button } from "@/components/layout/Button";
import type { TabItemStoryblok } from "@/component-types-sb";

type TabItemProps = {
  blok: TabItemStoryblok;
};

const TabItem = ({ blok }: TabItemProps) => (
  <Tab.Panel
    {...storyblokEditable(blok)}
    className="bg-white p-3 ring-white ring-opacity-60 ring-offset-2 ring-offset-brand-primary200 focus:outline-none focus:ring-2"
  >
    <p className="text-left font-medium leading-5 text-gray-500">
      {blok.content}
    </p>
    <div
      className={clsx("prose-a:font-semibold", {
        "columns-2": blok.columns == "2",
        "columns-3": blok.columns == "3",
      })}
    >
      {blok.body?.map((nestedBlok) => (
        <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
      ))}
      {blok.image && (
        <Image
          className={"object-contain"}
          src={blok.image.filename}
          width={300}
          height={200}
          alt={blok.image.alt || ""}
        />
      )}
    </div>

    <div className="flex flex-row justify-between items-center mt-4 pt-4 border-t border-dotted border-gray-400">
      {blok.link?.cached_url && (
        <Link
          href={blok.link?.url || `/${blok.link?.cached_url}`}
          target={blok.link.target || "_self"}
          className="flex gap-1 items-center font-semibold underline decoration-2 decoration-brand-primary100 pointer"
        >
          {blok.label}
          {blok.link.target === "_blank" && (
            <ArrowTopRightOnSquareIcon className="w-4 h-4 " />
          )}
        </Link>
      )}

      {blok.details && (
        <Link
          href={`/${(blok.details as any)?.full_slug}`}
          className="flex justify-center"
        >
          <Button arrow colorScheme="secondary" className="my-4">
            {(blok.details as any)?.name}
          </Button>
        </Link>
      )}
    </div>
  </Tab.Panel>
);

export default TabItem;
