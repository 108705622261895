import Link from "next/link";
import { storyblokEditable, StoryblokComponent } from "@storyblok/react/rsc";
import { renderRichText } from "@storyblok/react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { clsx } from "clsx";

import { Breadcrumb } from "@/components/layout/Breadcrumb";
import { H1, H3, RichText } from "@/components/typography";
import type { JobStoryblok, CampsiteStoryblok } from "@/component-types-sb";
import { getJobs } from "@/lib/storyblok/getJobs";

type JobProps = {
  blok: JobStoryblok & { campsites?: CampsiteStoryblok[] };
};

const Job = ({ blok }: JobProps) => (
  <article className="w-full" {...storyblokEditable(blok)}>
    <section className="bg-white relative max-w-6xl mx-auto grid grid-cols-3 gap-8 p-4">
      <div className="col-span-3 md:col-span-2 md:p-8">
        <header>
          <Breadcrumb
            items={[
              { href: "/karriere", title: "Karriere" },
              { href: "/jobs", title: "Stellenanzeigen" },
              { title: blok.title },
            ]}
          />
          <H1 colorScheme="primary">{blok.title}</H1>
          <H3 as="h2">{blok.subline}</H3>
        </header>

        <RichText html={renderRichText(blok.description)} />

        <div className="col-span-3 py-4">
          {blok.body?.map((nestedBlok: any) => (
            <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
          ))}
        </div>
      </div>

      {blok.campsites && blok.campsites?.length > 0 && (
        <aside className="col-span-3 md:col-span-1 md:p-8">
          <H3 colorScheme="secondary">Standorte</H3>

          {blok.campsites.map((nestedBlok: any) => (
            <Disclosure key={nestedBlok.id}>
              {({ open }) => (
                <div className="border-t-2 border-gray-200">
                  <Disclosure.Button className="flex w-full justify-between py-2 text-left font-medium text-brand-primary100 focus:outline-none focus-visible:ring focus-visible:ring-brand-primary200 focus-visible:ring-opacity-75">
                    <span className="text-lg font-semibold">
                      {nestedBlok.name}
                    </span>
                    <ChevronDownIcon
                      className={clsx("h-5 w-5 text-brand-primary100", {
                        ["rotate-180 transform"]: open,
                      })}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pt-1 pb-4 text-sm text-gray-500">
                    <address className="not-italic leading-relaxed">
                      <a href={`tel:${nestedBlok.content?.telephone}`}>
                        {nestedBlok.content?.telephone}
                      </a>
                      <br />
                      <Link href={`/${nestedBlok.full_slug}`}>Webseite</Link>
                    </address>
                  </Disclosure.Panel>
                </div>
              )}
            </Disclosure>
          ))}
        </aside>
      )}
    </section>

    {blok.footer?.map((nestedBlok: any) => (
      <StoryblokComponent blok={nestedBlok} key={nestedBlok._uid} />
    ))}
  </article>
);

export default Job;
